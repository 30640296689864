<template>

<com-content class="feature-wrapper">

	<com-content :inner="true" class="feature">

		<div class="feature-image" :class="{'is-active': playing}" v-on:click="onPlayClick">

			<div class="feature-image-cover" v-if="!playing">

				<i class="feature-image-cover-icon fa-solid fa-play"></i>

			</div>
		
			<video ref="video" preload="auto" :controls="(playing) ? 'controls' : false" :poster="'/img/' + poster">
				<source :src="$api.api + '../../files/' + video" type="video/mp4">
			</video>

		</div>

		<div class="feature-text">

			<h2>{{ title }}</h2>

			<div class="feature-text-tags">
				<div v-for="(tag, index) in tags" :key="index">{{ tag }}</div>
			</div>

		</div>

	</com-content>
	
</com-content>

</template>

<script>

export default {

	props: ['title', 'poster', 'video', 'tags'],
	
	components: {

		'com-content': () => import('./../common/Content'),

	},

	data: function() {

		return {
			playing: false
		}

	},

	methods: {

		onPlayClick: function() {

			this.playing = true

			this.$refs.video.play()

		}

	}

}

</script>

<style scoped>

.feature-wrapper {
	background-color: #fff;
}

.is-desktop .feature-wrapper:nth-child(odd) {
	background-color: #efefef;
}

.feature {
	display: flex;
	padding: 40px 0px;
	align-items: center;
	flex-direction: row;
	border-bottom: 1px solid #eee;
}

.is-mobile .feature {
	padding: 20px 10px;
	border-bottom: 0px;
	flex-direction: column;
	margin-bottom: 20px;
}

.is-mobile .feature:last-child {
	margin-bottom: 0px;
}

.feature-image {
	width: 400px;
	flex-shrink: 0;
	border-radius: 8px;
	overflow: hidden;
	cursor: pointer;
}

.feature-image video {
	width: 100%;
	margin: 0px;
	display: block;
	height: auto;
}

.feature-image.is-active {
	border: 1px solid #ddd;
}

.feature-image-cover {
	position: absolute;
	z-index: 2;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.feature-image-cover-icon {
	position: absolute;
	z-index: 2;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 64px;
	color: #ffbe7a;
	transition: transform 100ms linear;
}

.feature-image-cover:hover .feature-image-cover-icon {
	transform: translate(-50%, -50%) scale(1.1);
}

.is-mobile .feature-image {
	width: 100%;
	height: 240px;
	margin-bottom: 20px;
}

.feature-text {
	padding-left: 40px;
	color: #fff;
	flex-grow: 1;
}

.is-mobile .feature-text {
	padding-left: 0px;
}

.feature-text h2 {
    color: #444;
	font-weight: 500;
	font-size: 28px;
    margin-bottom: 20px;
}

.feature-text p {
	color: #666;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	margin-bottom: 20px;
}

.feature-text-tags {
	display: flex;
	flex-wrap: wrap;
}

.feature-text-tags > div {
	background-color: #1e3054;
    color: #fff;
	font-weight: 400;
	padding: 4px 8px;
	border-radius: 4px;
	font-size: 14px;
	margin: 0px 4px 4px 0px;
}

</style>